import { apiNoTokenAuthSlice } from "@/redux/app/api/apiSlice";

export const authNoTokenApiSlice = apiNoTokenAuthSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVerifyEmail: builder.query({
            query: ({ userId, token }) => ({
                url: `/api/v1/auth/verify-email/${userId}/${token}`,
            }),
        }),
        login: builder.mutation({
            query: (loginValues) => ({
                url: "/api/v1/auth/login",
                method: "POST",
                body: { ...loginValues },
            }),
        }),
        register: builder.mutation({
            query: (registerValues) => ({
                url: "/api/v1/auth/register",
                method: "POST",
                body: { ...registerValues },
            }),
        }),
        forgetPassword: builder.mutation({
            query: (values) => ({
                url: "/api/v1/auth/forget-password",
                method: "POST",
                body: { ...values },
            }),
        }),
        verifyResetCode: builder.mutation({
            query: (values) => ({
                url: "/api/v1/auth/verify-reset-code",
                method: "POST",
                body: { ...values },
            }),
        }),
    }),
});

export const {
    useGetVerifyEmailQuery,
    useLoginMutation,
    useRegisterMutation,
    useForgetPasswordMutation,
    useVerifyResetCodeMutation,
} = authNoTokenApiSlice;
