"use client";
import { useRouter } from "next/navigation";
import * as Yup from "yup";
// import getGoogleOauthURL from "@/utils/auth/oauth/getGoogleOauthURL";
// import getFacebookOauthURL from "@/utils/auth/oauth/getFacebookOauthURL";
import { setCredentials } from "@/redux/features/local/auth/authSlice";
import getFromStorage from "@/utils/localStorage/getFromStorage";
import setToLocalStorage from "@/utils/localStorage/setToLocalStorage";
import sanitizeValues from "@/utils/auth/sanitizeData/localStorage/sanitizeValues";
import { useState, useRef } from "react";
import getErrorMsg from "@/utils/auth/errorMsg/getErrorMsg";
import { useAppDispatch } from "@/redux/app/hooks";
import { useLoginMutation } from "@/redux/features/api/auth/authNoTokenApiSlice";

interface FormValues {
    email: string;
    password: string;
}

function useLoginSection() {
    const [errServerMsg, setErrServerMsg] = useState<string | []>("");
    const errRef = useRef<HTMLDivElement>(null);

    const initialValues: FormValues = {
        email: "",
        password: "",
    };

    const savedValues: FormValues = {
        email: getFromStorage("email"),
        password: "",
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("عنوان البريد الإلكتروني غير صالح")
            .required("البريد الالكتروني مطلوب").min(3, "عذرًا، يجب أن لا يقل طول بريدك الإلكتروني عن 3 أحرف").max(128, "عذرًا، يجب أن لا يزيد طول بريدك الإلكتروني عن 128 حرفًا"),
        password: Yup.string()
            .required("كلمة السر مطلوبة")
            .min(8, "يجب أن تتكون كلمة السر من  8 أحرف على الأقل")
            .max(64, "يجب أن تتكون كلمة السر من  64 أحرف على الأقل"),
    });

    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useAppDispatch();
    const router = useRouter();

    const handleSubmit = async (values: FormValues, submitActions: any) => {
        setToLocalStorage(sanitizeValues(values));

        try {
            const userData = await login(values).unwrap();
            dispatch(setCredentials({ ...userData?.data, email: values?.email }));
            submitActions.resetForm();
            router.push("/");
            setErrServerMsg("");
        } catch (err: any) {
            const errMsg = getErrorMsg(err, "فشل في تسجيل الدخول");
            setErrServerMsg(errMsg);
            errRef.current?.focus();
        }
        submitActions.setSubmitting(false);
    };

    // const handleGoogle = () => {
    //     getGoogleOauthURL("", "");
    // };

    // const handleFacebook = () => {
    //     getFacebookOauthURL("", "");
    // };

    return {
        initialValues,
        savedValues,
        validationSchema,
        handleSubmit,
        // handleGoogle,
        // handleFacebook,
        isLoading,
        errRef,
        errServerMsg,
    };
}

export default useLoginSection;
