"use client";
import Link from "next/link";
import Image from "next/image";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { CiUser } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
// import { FaGoogle } from "react-icons/fa";
// import { FaFacebookF } from "react-icons/fa";
import TextError from "@/components/UI/common/textError/TextError";
import useLoginSection from "@/hooks/pages/auth/sections/loginSection/useLoginSection";
import useToggleViewPass from "@/hooks/pages/auth/toggleViewPass/useToggleViewPass";
import useFocusElement from "@/hooks/pages/auth/focusElement/useFocusElement";
import SubmitBtn from "@/components/UI/common/buttonsOrLinks/SubmitBtn";
import logoLightPure from "@/../public/logo/logoLightPure.png";
import logoDarkPure from "@/../public/logo/logoDarkPure.png";

import ServerError from "@/components/UI/common/textError/ServerError";
interface FormValues {
    email: string;
    password: string;
}
function LoginSection() {
    const {
        initialValues,
        savedValues,
        validationSchema,
        handleSubmit,
        // handleGoogle,
        // handleFacebook,
        isLoading,
        errRef,
        errServerMsg,
    } = useLoginSection();

    const { typeFieldPass, handleViewPass } = useToggleViewPass();
    const { elementRef } = useFocusElement();
    return (
        <section id="login-section" className="auth">
            <div className="container">
                <div className="form-box">
                    <div className="form-header">
                        <div className="form-header-top">
                            <div className="form-header-left">
                                <h2 className="form-header-title">تسجيل الدخول</h2>
                            </div>
                            <div className="form-header-right">
                                <span className="form-header-logo elianto"><span className="aqua-color elianto">Physics </span>Nerds</span>
                                <div className="image-hand">
                                    <div className="logo-image-hand">
                                        <Image src={logoLightPure} alt="logo" className="logoLight" width={100} height={100} />
                                        <Image src={logoDarkPure} alt="logo" className="logoDark" width={100} height={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="form-header-description">
                            يسرنا أن نرحب بكم
                        </p>
                    </div>
                    {errServerMsg && <ServerError errServerMsg={errServerMsg} errRef={errRef} />}
                    <Formik
                        initialValues={savedValues || initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik: FormikProps<FormValues>) => (
                            <Form>
                                <div className="form-middle">
                                    <div className="field-box email-box">
                                        <div className="field-wrapper email-field-wrapper">
                                            <CiUser
                                                className={
                                                    "icon-field icon-email"
                                                }
                                            />
                                            <Field
                                                innerRef={
                                                    !savedValues.email
                                                        ? elementRef
                                                        : null
                                                }
                                                type="email"
                                                id="email"
                                                name="email"
                                                aria-label="الإيميل"
                                                placeholder="الإيميل"
                                                className="input-padding"
                                                autoComplete="on"

                                            />
                                        </div>
                                        <ErrorMessage
                                            name="email"
                                            component={TextError}
                                        />
                                    </div>
                                    <div className="field-box password-box">
                                        <div className="field-wrapper password-field-wrapper">
                                            <CiLock
                                                className={
                                                    "icon-field icon-password"
                                                }
                                            />
                                            <Field
                                                innerRef={
                                                    savedValues.email
                                                        ? elementRef
                                                        : null
                                                }
                                                type={typeFieldPass}
                                                id="password"
                                                name="password"
                                                aria-label="كلمة السر"
                                                placeholder="كلمة السر"
                                                className="input-padding"
                                                autoComplete="current-password"
                                            />
                                            {typeFieldPass === "password" ? (
                                                <IoMdEye
                                                    onClick={handleViewPass}
                                                    className="icon-field-pass-view"
                                                />
                                            ) : (
                                                <IoMdEyeOff
                                                    onClick={handleViewPass}
                                                    className="icon-field-pass-view"
                                                />
                                            )}
                                        </div>
                                        <ErrorMessage
                                            name="password"
                                            component={TextError}
                                        />
                                        <Link prefetch={false}
                                            href="/forget-password"
                                            className="forget-pass-link"
                                        >
                                            هل نسيت كلمة السر ؟
                                        </Link>
                                    </div>
                                </div>
                                <SubmitBtn
                                    isLoading={isLoading}
                                    formik={formik}
                                    text={"تسجيل الدخول"}
                                />
                            </Form>
                        )}
                    </Formik>
                    {/*
                     <div className="line-break">
                        <span>or</span>
                    </div>
                    <div className="o-auth-box">
                        <button
                            onClick={handleGoogle}
                            className={"oauth-button outline-common-btn"}
                        >
                            <FaGoogle className={"oauth-icon"} />
                            <span>تسجيل بواسطة جوجل</span>
                        </button>
                        <button
                            onClick={handleFacebook}
                            className={"oauth-button outline-common-btn"}
                        >
                            <FaFacebookF className={"oauth-icon"} />
                            <span>تسجيل بواسطة الفيسبوك</span>
                        </button>
                    </div>
                     */}
                    <div className="toggle-login-pages">
                        <p>
                            لا تملك حساب؟{" "}
                            <Link prefetch={false}
                                href="/register"
                                className="toggle-login-pages-link"
                            >
                                سجل معنا
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoginSection;