import { useEffect, useRef } from "react";

function useFocusElement() {
    const elementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (elementRef?.current) {
            elementRef.current.focus();
        }
    }, []);

    return { elementRef };
}

export default useFocusElement;
