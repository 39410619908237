import { useState } from "react";

function useToggleViewPass() {
    const [typeFieldPass, setTypeFieldPass] = useState("password");
    const [typeFieldCom, setTypeFieldCom] = useState("password");
    const [typeFieldCurrent, setTypeFieldCurrent] = useState("password");

    const handleViewPass = () => {
        typeFieldPass === "password"
            ? setTypeFieldPass("text")
            : setTypeFieldPass("password");
    };

    const handleViewCom = () => {
        typeFieldCom === "password"
            ? setTypeFieldCom("text")
            : setTypeFieldCom("password");
    };

    const handleViewCurrent = () => {
        typeFieldCurrent === "password"
            ? setTypeFieldCurrent("text")
            : setTypeFieldCurrent("password");
    };

    return { typeFieldPass, typeFieldCom, typeFieldCurrent, handleViewPass, handleViewCom, handleViewCurrent };
}

export default useToggleViewPass;
