function sanitizeValues(valuesObj: any) {
    let valuesObjClone = { ...valuesObj };
    if ("password" in valuesObjClone) delete valuesObjClone.password;
    if ("confirmPassword" in valuesObjClone) delete valuesObjClone.confirmPassword;
    if ("newPassword" in valuesObjClone) delete valuesObjClone.newPassword;
    if ("resetCode" in valuesObjClone) delete valuesObjClone.resetCode;
    return valuesObjClone;
}

export default sanitizeValues;
